import { Label } from '@/components/ui/label';
import { RadioGroupItem } from '@/components/ui/radio-group';

interface StrategyCardProps {
  value: string;
  label: string;
}

export const StrategyCard = ({ value, label }: StrategyCardProps) => {
  return (
    <div className='tw-relative'>
      <RadioGroupItem value={value} id={value} className='tw-peer tw-sr-only' />
      <Label
        htmlFor={value}
        className='tw-text-gray-500 tw-flex tw-flex-col tw-items-center tw-justify-between tw-rounded-md tw-border-2 tw-border-muted tw-bg-popover tw-p-4 hover:tw-bg-accent hover:tw-text-accent-foreground peer-data-[state=checked]:tw-border-green peer-data-[state=checked]:tw-text-gray-600'
      >
        {label}
      </Label>
    </div>
  );
};
