import './index.scss';

import { RatesFormValues } from '@/features/rules/rates/zod';
import { SeasonSelect } from '@/pages/rules/components/season-select/season-select';
import { unsavedDataChanged } from 'features/rules/redux/rules-slice';
import { useUser } from 'features/users/context/userContext';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { DerivedRateForm } from './derived-rate-form';
import { PricingStrategyForm } from './pricing-strategy-form';
import { RateBoundaryForm } from './rate-boundary-form';
import { RateProxyForm } from './rate-proxy-form';

export const RatesForm = () => {
  const { isReadOnly } = useUser();
  const dispatch = useAppDispatch();

  const { values, dirty, handleChange, setFieldValue } =
    useFormikContext<RatesFormValues>();

  useEffect(() => {
    console.log('dirty', dirty);
    console.log('values', values);
    dispatch(unsavedDataChanged(dirty));
  }, [dirty, dispatch, values]);

  return (
    <div className='rates-form'>
      <div className='row season-name'>
        <SeasonSelect />
      </div>
      <PricingStrategyForm
        setFieldValue={setFieldValue}
        pricingStrategy={values.pricingStrategy}
      />
      <RateBoundaryForm
        handleChange={handleChange}
        isReadOnly={isReadOnly}
        values={values}
      />
      <DerivedRateForm
        handleChange={handleChange}
        isReadOnly={isReadOnly}
        values={values}
      />
      <RateProxyForm
        handleChange={handleChange}
        isReadOnly={isReadOnly}
        values={values}
      />
    </div>
  );
};
