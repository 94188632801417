import { PricingStrategy } from 'graphql/gql-types';
import { RadioGroup } from '@/components/ui/radio-group';
import { StrategyCard } from './strategy-card';

const pricingStrategyOptions = [
  { label: 'Conservative', value: PricingStrategy.conservative },
  { label: 'Neutral', value: PricingStrategy.neutral },
  { label: 'Aggressive', value: PricingStrategy.aggressive },
];

interface PricingStrategyFormProps {
  setFieldValue: (field: string, value: PricingStrategy) => void;
  pricingStrategy: PricingStrategy;
}

export const PricingStrategyForm = ({
  setFieldValue,
  pricingStrategy,
}: PricingStrategyFormProps) => {
  return (
    <div className='row'>
      <h2 className='header'>Pricing Strategy</h2>
      <RadioGroup
        value={pricingStrategy}
        defaultValue='neutral'
        className='tw-grid tw-grid-cols-3 tw-gap-4'
        onValueChange={(value: PricingStrategy) =>
          setFieldValue('pricingStrategy', value)
        }
        name='pricingStrategy'
      >
        {pricingStrategyOptions.map((option) => (
          <StrategyCard
            key={option.value}
            value={option.value}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </div>
  );
};
