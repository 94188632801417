import { TableColumn, TableStyles } from 'react-data-table-component';
import {
  formattedCurrency,
  twoDecimalRateFormatter,
} from 'pages/MyView/tableCustomCellRender';

import DataTable from 'components/data-table/data-table-base';
import { RateChangeLog } from 'graphql/gql-types';
import { dataDate } from 'features/dates/date-helpers';
import styles from 'common/_variables.module.scss';
import { useMemo } from 'react';
import { usePropertyContext } from '@/context/propertyContext';
import { useUser } from '@/features/users/context/userContext';

const customStyles: TableStyles = {
  table: {
    style: {
      border: 'none',
      height: '80%',
    },
  },
  headCells: {
    style: {
      fontSize: '15px',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#6e6e6e',
    },
  },
  cells: {
    style: {
      fontSize: '12px',
      color: '#4a4a4a',
    },
  },
};

interface RateChangeTableProps {
  data: RateChangeLog[];
}

export const RateChangeTable = ({ data = [] }: RateChangeTableProps) => {
  const { locale } = useUser();
  const {
    property: { currencyCode },
  } = usePropertyContext();

  const columns: TableColumn<RateChangeLog>[] = useMemo(() => {
    const rateFormatter = (value: number = 0) =>
      formattedCurrency(
        value.toString(),
        locale,
        currencyCode,
        true,
        twoDecimalRateFormatter
      );
    return [
      {
        name: 'Stay Date',
        id: 'stayDate',
        center: true,
        selector: (row) => row.stayDate!,
        sortable: true,
        cell: (row) => dataDate(row.stayDate!),
      },
      {
        name: 'Previous Rate',
        center: true,
        selector: (row) => row.previousRate!,
        sortable: true,
        cell: (row) => rateFormatter(row.previousRate),
      },
      {
        name: 'New Rate',
        center: true,
        selector: (row) => row.newRate!,
        sortable: true,
        cell: (row) => rateFormatter(row.newRate),
      },
      {
        name: 'Change',
        center: true,
        selector: (row) => row.newRate! - row.previousRate!,
        sortable: true,
        cell: (row) => {
          if (row.previousRate === 0) {
            return 'Opened';
          }

          const change = row.newRate! - row.previousRate!;
          return (
            <div
              style={{
                color: change > 0 ? styles.colorGreen : styles.colorWarning,
              }}
            >
              {change > 0 ? '+' : ''}
              {rateFormatter(change)}
            </div>
          );
        },
      },
      {
        name: 'Updated By',
        center: true,
        selector: (row) => row.updatedBy,
        sortable: true,
        cell: (row) => row.updatedBy.toUpperCase(),
      },
      {
        name: 'Uploaded On',
        id: 'createdAt',
        center: true,
        selector: (row) => row.createdAt,
        sortable: true,
      },
    ];
  }, [currencyCode, locale]);

  return (
    <DataTable
      columns={columns}
      data={data}
      customStyles={customStyles}
      defaultSortAsc={false}
      defaultSortFieldId={'createdAt'}
    />
  );
};
