import {
  dataDate,
  formatDate,
  getDayOfWeek,
} from 'features/dates/date-helpers';
import { useEffect, useState } from 'react';

import { ChartData } from '../types/RecReviewTypes';
import { DOW_ALL } from 'features/search/search-helpers';
import { Maybe } from 'types/maybe-type';
import { useLiveDataChart } from 'features/my-view/hooks/use-live-data';
import { usePropStayViewChart } from 'features/my-view/hooks/use-prop-stay-view';

type LiveData = {
  stay_date: string;
  available_rooms_live: number;
  current_otb_live: number;
  current_rate_live: number;
  group_blocked_live: number;
  group_booked_live: number;
  ooo_live: number;
  total_capacity_live: number;
  transient_capacity_live: number;
};

type SnapshotData = {
  stay_date: string;
  available_cap: number;
  available_rooms: number;
  current_otb: number;
  current_rate: number;
  group_blocked: number;
  group_booked: number;
  market_rate: number;
  out_of_order: number;
  overbooking: number;
  rec_rate: number;
  total_capacity: number;
  total_fcst: number;
  transient_cap: number;
  yoy_rate_lyst: number;
  total_fcst_incl_group: number;
};

export const getCategories = (data?: string[]) => {
  let monthWiseDictionary: { [month: string]: string[] } = {}; //monthWiseDictionary is dictionary with values {Sep: [], Oct: [], so on...}
  data?.forEach((date) => {
    const month = date.split(' ')[1].split(',')[0];
    if (month in monthWiseDictionary) {
      monthWiseDictionary[month].push(date);
    } else {
      monthWiseDictionary[month] = [date];
    }
  });

  /**
   * https://github.com/blacklabel/grouped_categories
   * categoriesArray is categories array of objects with keys as 'categories and name'
   * [{categories: ["07 Sep, Thursday","08 Sep, Friday"], name: "Sept"}, {}....so on]
   */
  let categoriesArray = [];
  for (let key in monthWiseDictionary) {
    let categoryObj = { categories: monthWiseDictionary[key], name: key };
    categoriesArray.push(categoryObj);
  }
  return categoriesArray;
};

export const useRecReviewCharts = (
  propertyId: string,
  dayType: string,
  days: string
) => {
  const [chartData, setChartData] = useState<ChartData>();
  const { data, loading } = usePropStayViewChart({
    duration: 365,
    propertyId,
    selectedDays: DOW_ALL,
  });
  const { data: liveData, loading: liveLoading } = useLiveDataChart({
    propertyId,
  });

  const formatRate = (rate: number) => {
    if (!rate) return 0;
    return parseFloat(rate.toFixed(2).replace(/(\.\d{2})\d+$/, '$1'));
  };

  const formatResultForHighChartData = (data: any[]) => {
    let available_cap: number[] = [],
      available_rooms: number[] = [],
      bw_rates: number[] = [],
      current_otb: number[] = [],
      group_blocked: number[] = [],
      group_booked: number[] = [],
      market_rates: number[] = [],
      out_of_order: number[] = [],
      overbooking: number[] = [],
      rec_rates: number[] = [],
      stay_dates: string[] = [],
      total_capacity: number[] = [],
      total_fcst: number[] = [],
      transient_booked: number[] = [],
      transient_cap: number[] = [],
      yoy_rate_lyst: number[] = [],
      total_fcst_incl_group: number[] = [];
    for (let i = 0; i < data.length; i++) {
      let calculateTransientBookedVal = (
        otb: number,
        grpBlocked: number,
        grpBooked: number
      ) => {
        return (otb || 0) - (grpBlocked || 0) - (grpBooked || 0);
      };

      let calculateFcstInclGrp = (total_fcst: number) => {
        return total_fcst || 0;
      };

      available_cap.push(data[i].available_cap || 0);
      available_rooms.push(data[i].available_rooms || 0);
      current_otb.push(data[i].current_otb || 0);
      bw_rates.push(formatRate(data[i].current_rate) || 0);
      group_blocked.push(data[i].group_blocked || 0);
      group_booked.push(data[i].group_booked || 0);
      market_rates.push(formatRate(data[i].market_rate) || 0);
      out_of_order.push(data[i].out_of_order || '');
      overbooking.push(data[i].overbooking || '');
      rec_rates.push(formatRate(data[i].rec_rate) || 0);
      stay_dates.push(formatDate(data[i].stay_date, 'DD MMM, dddd') || '');
      total_capacity.push(data[i].total_capacity || 0);
      total_fcst.push(data[i].total_fcst || 0);
      transient_booked.push(
        calculateTransientBookedVal(
          data[i].current_otb,
          data[i].group_blocked,
          data[i].group_booked
        )
      );
      transient_cap.push(data[i].transient_cap || 0);
      yoy_rate_lyst.push(formatRate(data[i].yoy_rate_lyst) || 0);
      total_fcst_incl_group.push(calculateFcstInclGrp(data[i].total_fcst));
    }
    const formattedData = {
      stay_dates,
      total_fcst,
      current_otb,
      bw_rates,
      rec_rates,
      market_rates,
      available_cap,
      available_rooms,
      yoy_rate_lyst,
      group_blocked,
      group_booked,
      out_of_order,
      overbooking,
      transient_cap,
      transient_booked,
      total_capacity,
      total_fcst_incl_group,
    };
    return formattedData;
  };

  const filterDataBySelection = (
    data: any,
    noOfDays: string,
    dayType: string
  ) => {
    const noOfDaysData = data.slice(0, noOfDays); // .slice will give first 30/60/90/120 days based on selection.
    switch (dayType) {
      case 'weekends': {
        return noOfDaysData.filter(
          (ele: { stay_date: Maybe<string> }) => getDayOfWeek(ele.stay_date) > 4
        );
      }
      case 'weekdays': {
        return noOfDaysData.filter(
          (ele: { stay_date: Maybe<string> }) => getDayOfWeek(ele.stay_date) < 5
        );
      }
      default: {
        return noOfDaysData;
      }
    }
  };

  useEffect(() => {
    let result: SnapshotData[] = [];
    if (data && liveData && !loading && !liveLoading) {
      const mergedData: SnapshotData[] = data.getPropertyStayView.map(
        (snapshotItem: SnapshotData) => {
          const liveItem = liveData.getLiveData.find(
            (l: LiveData) =>
              dataDate(l.stay_date) === dataDate(snapshotItem.stay_date)
          );

          if (liveItem) {
            return {
              ...snapshotItem,
              available_rooms: liveItem.available_rooms_live,
              current_otb: liveItem.current_otb_live,
              current_rate: liveItem.current_rate_live, // aka bw_rate
              group_blocked: liveItem.group_blocked_live,
              group_booked: liveItem.group_booked_live,
              out_of_order: liveItem.ooo_live,
              total_capacity: liveItem.total_capacity_live,
              transient_cap: liveItem.transient_capacity_live,
            };
          } else {
            return snapshotItem;
          }
        }
      );

      result = mergedData;
    } else if (data && !liveData && !loading) {
      result = data.getPropertyStayView;
    }

    const filterData = filterDataBySelection(result, days, dayType);
    setChartData(formatResultForHighChartData(filterData));
  }, [data, liveData, loading, liveLoading, days, dayType]);

  return { chartData, loading };
};
