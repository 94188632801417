import './otb-widget.scss';

import { Chart } from 'components/Chart/Chart';
import { CustomCard } from '../custom-card';
import { FormattedRateLevels } from 'pages/home/hooks/use-rate-levels-for-date';
import { LoadingBar } from 'components/loading-bar/loading-bar';
import { labels } from 'locales/en.label';
import { otbChartConfig } from './config';

type OtbWidgetProps = {
  rateLevels: FormattedRateLevels | undefined;
  total: number | undefined;
  loading: boolean;
};

export const OtbWidget = ({ rateLevels, total, loading }: OtbWidgetProps) => {
  const chartOptions = { ...otbChartConfig };
  const data: { name: string; y: number }[] = [];
  rateLevels?.forEach(({ level, otb, liveOtb }) => {
    if (liveOtb === null && otb === null) return;
    data.push({
      name: level,
      y: liveOtb ?? otb,
    });
  });

  chartOptions.series[0].data = data;

  return (
    <CustomCard className='otb-widget'>
      {loading ? (
        <LoadingBar />
      ) : (
        <div className='content'>
          {rateLevels && total !== undefined ? (
            <>
              <div className='chart-container'>
                <Chart options={chartOptions} />
              </div>
              <div className='total'>
                <h1>{total}</h1>
                <span>{labels.home.otb}</span>
              </div>
            </>
          ) : (
            <span className='no-data'>{labels.home.no_data}</span>
          )}
        </div>
      )}
    </CustomCard>
  );
};
